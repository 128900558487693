.portfolio {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.portfolio span:nth-of-type(1) {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}
.portfolio span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2rem;
  font-weight: bold;
}

/*Slider*/

.portfolio .swiper {
  overflow: visible !important;
}
.portfolio-slider {
  margin-top: 3rem;
  width: 100%;
}
.portfolio-slider .swiper-slide {
  width: 20rem;
}
.portfolio img {
  width: 20rem;
  height: 11rem;
  filter: drop-shadow(-12px15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 18px;
}
