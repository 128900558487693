.works {
  height: 90vh;
  padding: 0 3rem 0 3rem;
  margin-top: 4rem;
  display: flex;
}

/*Right side*/

.w-right {
  position: relative;
}
.w-mainCircle {
  position: relative;
  left: 9rem;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  top: 2rem;
  background: white;
}
.w-mainCircle > * {
  position: absolute;
}
.w-mainCircle > :nth-child(1) {
  top: -3rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(2) {
  top: 5rem;
  left: -3rem;
}
.w-mainCircle > :nth-child(3) {
  top: 5rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(4) {
  top: 5rem;
  left: 15rem;
}
.w-mainCircle > :nth-child(5) {
  top: 13rem;
  left: 6rem;
}
.w-secCircle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 50%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  box-shadow: var(--smboxShadow);
  background: white;
}

.w-secCircle > img {
  transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 50%;
  z-index: -1;
}
.blueCircle {
  background: #194988;
  left: 18rem;
  top: 0rem;
}
.yellowCircle {
  background: #f5c32c;
  left: 18rem;
  top: 8rem;
}
@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 55rem;
    padding: 0;
    gap: 5rem;
  }

  .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: start;
    transform: scale(0.7);
  }
  .w-mainCircle {
    position: static;
    width: 18rem;
    height: 18rem;
  }
  .w-secCircle {
    width: 4rem;
    height: 4rem;
  }
  .w-mainCircle > :nth-child(1) {
    top: -2rem;
    left: 6rem;
  }
  .w-mainCircle > :nth-child(2) {
    top: 6rem;
    left: -3rem;
  }
  .w-mainCircle > :nth-child(3) {
    top: 6.5rem;
    left: 5.5rem;
  }
  .w-mainCircle > :nth-child(4) {
    top: 6rem;
    left: 14rem;
  }
  .w-mainCircle > :nth-child(5) {
    top: 15rem;
    left: 6rem;
  }

  .w-secCircle > img {
    transform: scale(0.4);
  }
  .w-backCircle {
    left: 7rem !important;
  }
}
